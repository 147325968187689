import React, { useState, useEffect, useRef, useCallback } from "react";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  numberValidation,
  phoneOnChange,
  phoneValidation,
  websiteValidation,
} from "../../validation/formValidation";
import FormInput from "../TechSupport/FormInput";
import { connect } from "react-redux";
import { postSales, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";
import axios from "../../api";

function Sale(props) {
  const { postSales, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [saleData, setSaleData] = useState({
    clientType: "",
    referredBy: "",
    company: "",
    website: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    altAdderss: "",
    city: "",
    state: "",
    postal: "",
    country: "",
    comments: "",
    isNewsletter: false,
  });

  const [saleDataError, setSaleDataError] = useState({
    clientTypeError: "",
    referredByError: "",
    companyError: "",
    websiteError: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
    addressError: "",
    altAdderssError: "",
    cityError: "",
    stateError: "",
    postalError: "",
    countryError: "",
    commentsError: "",
    isNewsletterError: false,
  });

  const onChangeSaleData = (e) => {
    if (e.target.name === "website") {
      noSpaceOnChange(e.target.value) && setSaleData({ ...saleData, website: e.target.value });
    } else if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setSaleData({ ...saleData, email: e.target.value });
    } else if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setSaleData({ ...saleData, phone: e.target.value });
    } else if (e.target.name === "isnewsletter") {
      setSaleData({ ...saleData, isNewsletter: !saleData.isNewsletter });
    } else if (e.target.name === "postal") {
      noSpaceOnChange(e.target.value) &&
        numberValidation(e.target.value) &&
        setSaleData({ ...saleData, postal: e.target.value });
    } else {
      setSaleData({ ...saleData, [e.target.name]: e.target.value });
    }
  };

  const onBlurSaleInput = (e) => {
    if (e.target.name === "website" && !websiteValidation(saleData.website)) {
      setSaleDataError({ ...saleDataError, websiteError: "Please enter a valid URL" });
    } else if (e.target.name === "email" && !emailValidation(saleData.email)) {
      setSaleDataError({ ...saleDataError, emailError: "Please enter a valid email" });
    } else if (e.target.name === "phone" && !phoneValidation(saleData.phone)) {
      setSaleDataError({ ...saleDataError, phoneError: "Phone number should be of 10 digits" });
    } else if (e.target.name === "company" && !notEmptyValidation(saleData.company)) {
      setSaleDataError({ ...saleDataError, companyError: "Company is required" });
    } else if (e.target.name === "lastName" && !notEmptyValidation(saleData.lastName)) {
      setSaleDataError({ ...saleDataError, lastNameError: "Last name is required" });
    } else if (e.target.name === "address" && !notEmptyValidation(saleData.address)) {
      setSaleDataError({ ...saleDataError, addressError: "Address is required" });
    } else if (e.target.name === "city" && !notEmptyValidation(saleData.city)) {
      setSaleDataError({ ...saleDataError, cityError: "City is required" });
    }
  };

  const onSubmitValidation = (e) => {
    if (
      websiteValidation(saleData.website) &&
      emailValidation(saleData.email) &&
      phoneValidation(saleData.phone) &&
      notEmptyValidation(saleData.company) &&
      notEmptyValidation(saleData.lastName) &&
      notEmptyValidation(saleData.address) &&
      notEmptyValidation(saleData.city)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!onSubmitValidation()) {
      return;
    }

    formStatusPending();

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });
        console.log(res)
        if (res.data) {
          setSaleData({
            clientType: "",
            referredBy: "",
            company: "",
            website: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            altAdderss: "",
            city: "",
            state: "",
            postal: "",
            country: "",
            comments: "",
            isNewsletter: false,
          });

          setSaleDataError({
            clientTypeError: "",
            referredByError: "",
            companyError: "",
            websiteError: "",
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            phoneError: "",
            addressError: "",
            altAdderssError: "",
            cityError: "",
            stateError: "",
            postalError: "",
            countryError: "",
            commentsError: "",
            isNewsletterError: false,
          });

          await postSales(saleData);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <div className="SaleMainContainer">
      <ListFreePrimary customClass="row-md-12 SaleSecondDiv">
        <div class="login d-flex align-items-center py-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-xl-12 mx-auto text-center">
                <h1 class="display-center-5">Fill out this form</h1>
                <form class="form-card" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
                  <div class="form-group flex-row d-flex">
                    <div className="w-100 mr-3">
                      <label>Client type</label>
                      <select
                        className="form-control form-control-sm"
                        name="clientType"
                        value={saleData.clientType}
                        onChange={onChangeSaleData}
                      >
                        <option value="Dealer">Dealer</option>
                        <option value="End user">End user</option>
                      </select>
                    </div>
                    <div className="w-100 ml-3">
                      <label>Referred by</label>
                      <select
                        className="form-control form-control-sm"
                        name="referredBy"
                        value={saleData.referredBy}
                        onChange={onChangeSaleData}
                      >
                        <option value="Dealer">Dealer</option>
                        <option value="End user">End User</option>
                        <option value="Consultant">Consultant</option>
                        <option value="Website">Website</option>
                        <option value="Trade Show">Trade Show</option>
                        <option value="Publication">Publication</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div class="row SaleFormInput justify-content-between text-left">
                    <FormInput
                      title="Company/Affliation"
                      type="text"
                      name="company"
                      value={saleData.company}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter company/Affliation"
                      required
                      error={saleDataError.companyError}
                    />
                    <FormInput
                      title="Website"
                      type="text"
                      name="website"
                      value={saleData.website}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter website"
                      error={saleDataError.websiteError}
                    />
                  </div>
                  <div class="row justify-content-between text-left">
                    <FormInput
                      title="First Name"
                      type="text"
                      name="firstName"
                      value={saleData.firstName}
                      onChange={onChangeSaleData}
                      placeholder="Enter first name"
                    />
                    <FormInput
                      title="Last Name"
                      type="text"
                      name="lastName"
                      value={saleData.lastName}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter last name"
                      required
                      error={saleDataError.lastNameError}
                    />
                  </div>
                  <div class="row justify-content-between text-left">
                    <FormInput
                      title="Email"
                      type="text"
                      placeholder="Enter your email"
                      name="email"
                      value={saleData.email}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      required
                      error={saleDataError.emailError}
                    />
                    <FormInput
                      title="Phone"
                      type="text"
                      name="phone"
                      value={saleData.phone}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter Phone"
                      required
                      error={saleDataError.phoneError}
                    />
                  </div>
                  <div class="row justify-content-between text-left">
                    <FormInput
                      title="Address"
                      type="text"
                      name="address"
                      value={saleData.address}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter address"
                      required
                      error={saleDataError.addressError}
                    />
                    <FormInput
                      title="Address 2"
                      type="text"
                      name="altAdderss"
                      value={saleData.altAdderss}
                      onChange={onChangeSaleData}
                      placeholder="Enter alt address"
                    />
                  </div>
                  <div class="row justify-content-between text-left">
                    <FormInput
                      title="Country"
                      name="country"
                      value={saleData.country}
                      onChange={onChangeSaleData}
                      type="text"
                      placeholder="Enter country"
                    />
                    <FormInput
                      title="State"
                      type="text"
                      name="state"
                      value={saleData.state}
                      onChange={onChangeSaleData}
                      placeholder="Enter State"
                    />
                  </div>
                  <div class="row justify-content-between text-left">
                    <FormInput
                      title="City"
                      type="text"
                      name="city"
                      value={saleData.city}
                      onChange={onChangeSaleData}
                      onBlur={onBlurSaleInput}
                      placeholder="Enter city"
                      required
                      error={saleDataError.cityError}
                    />
                    <FormInput
                      title="Postal"
                      type="text"
                      name="postal"
                      value={saleData.postal}
                      onChange={onChangeSaleData}
                      placeholder="Enter Postal"
                    />
                  </div>

                  <textarea
                    placeholder="Please include serial number if applicable"
                    class="pb-cmnt-textarea"
                    id="text"
                    name="comments"
                    value={saleData.comments}
                    onChange={onChangeSaleData}
                    rows="5"
                  ></textarea>

                  <div class="check">
                    <input
                      class="check-input"
                      type="checkbox"
                      name="isNewsletter"
                      value={saleData.isNewsletter}
                      onChange={onChangeSaleData}
                      id="flexCheckDefault1"
                    />
                    <label class="check-label" for="flexCheckDefault1">
                      Yes, please send me newsletter on KanexPro
                    </label>
                  </div>

                  <div>
                    <button class="btn btn-primary float-xs-right" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ListFreePrimary>
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postSales, formStatusPending, formStatusNull, formStatusCustom })(Sale);
