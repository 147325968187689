import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import LoadingOverlay from "../components/features/loading-overlay";

import { scrollTop } from "../utils";
import HomePage from "./home-route";

const ElementPages = React.lazy(() => import("./elements-route.js"));
const OtherPages = React.lazy(() => import("./others-route.js"));
// const HomePage = React.lazy(() => import("./home-route.js"));

export default function AppRoot() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
     <GoogleReCaptchaProvider reCaptchaKey="6Lc-I_IpAAAAAP1ov0Inj3lQWre_dHVccye04nIu" scriptProps={{ async: true }}>
    <React.Suspense fallback={<LoadingOverlay />}>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/elements`} component={ElementPages} />
        <Route path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages} />
        <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
      </Switch>
    </React.Suspense>
     </GoogleReCaptchaProvider>
  );
}
