import React from "react";

const FormInput = ({ type, placeholder, value, name, onChange, onBlur, required, error }) => {
  return (
    <>
      <div class="form-group col-md-6 flex-column d-flex justify-content-center">
        <input
          className="formInput"
          type={type}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          name={name}
          onBlur={onBlur}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        />
        {error && <p className="invalid-data mt-1 text-center">{error}</p>}
      </div>
    </>
  );
};

export default FormInput;
