import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-app-polyfill/ie11";

import { PersistGate } from "redux-persist/integration/react";

// import store
import store, { persistor } from "./store";

// import action
import { getAllProducts, refreshStore } from "./actions";

// import routes
import AppRoute from "./routes";

// import Utils
import { initFunctions } from "./utils";

import LoadingOverlay from "./components/features/loading-overlay";

export function Root() {
  // initFunctions();
  // store.dispatch(getAllProducts());

  // useEffect(() => {
  //   if (store.getState().modal.current !== 6) {
  //     store.dispatch(refreshStore(6));
  //   }
  // }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function() {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`,
      function() {
        console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
