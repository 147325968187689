import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getBannerProducts, setLoadingTrue } from "../../actions/products";
import { connect } from "react-redux";
import "./style.scss";

// import Custom Components
import OwlCarousel from "../features/owl-carousel";

// import Home Components.
import IntroSlide from "./intro-slide";
import BlogSection from "./blog-section";

// import Slider Settings
import { introSlider } from "../settings";

// import Data and Style.
import _data from "../../mock_data/data.json";
import style from "./style.scss";
import CarouselSecondary from "../../ui/carousel/CarouselSecondary";
import WarrantySection from "./WarrantySection";
import PartnersSection from "./PartnersSection";
import ProductsSection from "./ProductsSection";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";
import CarouselMatrix from "../../ui/carousel/cruselMatrix";

function HomePage(props) {
  const { products, error, getBannerProducts, setLoadingTrue } = props;

  useEffect(() => {
    setLoadingTrue();
    getBannerProducts();

    document.getElementById("menu-home").classList.add("active");

    style.use();

    return () => {
      setLoadingTrue();
      document.getElementById("menu-home").classList.remove("active");
      style.unuse();
    };
  }, []);

  //   if (error) {
  //     return <p>Error!</p>;
  //   }

  if (products.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>AV Systems Manufacturers | Audio Visual Systems Intregration</title>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></meta>
        <meta
          name="description"
          content="KanexPro is the leading AV Systems Solutions Manufacturer. We specialize in Audio Visual Integrations. Check out the best one-stop-shop for Audio Video equipment."
        ></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>

      <h1 className="d-none">Kanex Pro</h1>

      <div className="main">
        <div className="intro-slider-container">
        <CarouselSecondary className="banner-carousel">
            {products.map((item, index) => (
              <IntroSlide data={item} key={`intro_${index}`} />
            ))}
          </CarouselSecondary>
        </div>

        <div className="mb-5"></div>

        {/* <TrendyCollection /> */}

        <div className="mb-5"></div>

        <div className="mb-2"></div>

        <BlogSection />
        {/* <ProductsSection /> */}
        <WarrantySection />
        <PartnersSection />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    products: state.data.bannerProducts ? state.data.bannerProducts : [],
    error: state.data.error,
  };
};

export default connect(mapStateToProps, { getBannerProducts, setLoadingTrue })(HomePage);
