import * as types from "../constants/action-types";
import axios from "../api/index";
import { contactEmail } from "../constants/contactEmail";
import jwt_decode from "jwt-decode";
import { emptyCart } from "./products";

export const postDealer = (body) => async (dispatch) => {
  const emailBody = `Subject: ${contactEmail.dealer.subject} userEmail: ${body.email} newsletter: ${body.isNewsletter} mailFrom: ${contactEmail.dealer.email} name: ${body.firstName} ${body.lastName} company: ${body.company} phone: ${body.phone} inquiry: ${body.inquiry}`;
  try {
    await axios.post("/api/EmailManagement/DealerSignUp", {
      Subject: contactEmail.dealer.subject,
      UserEmail: body.email,
      EmailBody: emailBody,
      NewsletterSubscribe: body.isNewsletter,
      mailFrom: contactEmail.dealer.email,
      Name: body.firstName + " " + body.lastName,
      Company: body.company,
      phone: body.phone,
      email: body.email,
      comments: body.inquiry,
      mailTo: contactEmail.dealer.email,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postNewsletter = (body) => async (dispatch) => {
  try {
    await axios.post("/api/EmailManagement/NewsLetterSignup", {
      Name: body.name ? body.name : null,
      Email: body.email,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postPress = (body) => async (dispatch) => {
  const emailBody = `Subject: ${contactEmail.press.subject} userEmail: ${body.email} newsletter: ${body.isNewsletter} mailFrom: ${contactEmail.press.email} name: ${body.name} company: ${body.company} phone: ${body.phone} inquiry: ${body.inquiry}`;
  try {
    await axios.post("/api/EmailManagement/PressEnquiry", {
      Subject: contactEmail.press.subject,
      UserEmail: body.email,
      EmailBody: emailBody,
      NewsletterSubscribe: body.isNewsletter,
      mailFrom: body.email,
      Company: body.company,
      cid: body.company,
      Name: body.name,
      Phone: body.phone,
      Question: body.inquiry,
      mailTo: contactEmail.press.email,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postSales = (body) => async (dispatch) => {
  // const emailBody = `Subject: ${contactEmail.sales.subject} userEmail: ${body.email} newsletter: ${body.isNewsletter} mailFrom: ${contactEmail.sales.email} name: ${body.firstName} ${body.lastName} company: ${body.company} phone: ${body.phone} address: ${body.address} address2: ${body.altAdderss} city: ${body.city} state: ${body.state} zip: ${body.postal} country: ${body.country} refers: ${body.referredBy} comments: ${body.comments} clientType: ${body.clientType}`;
  try {
    await axios.post("/api/EmailManagement/SalesEnquiry", {
      Company: body.company,
      Site: body.website,
      Contact: body.phone,
      NewsletterSubscribe: body.isNewsletter,
      mailFrom: body.email,
      Phone: body.phone,
      last: body.lastName,
      email: body.email,
      address: body.address,
      address2: body.altAdderss,
      city: body.city,
      state: body.state,
      zip: body.postal,
      country: body.country,
      refers: body.referredBy,
      // interest: "string",
      // cid: "string",
      comments: body.comments,
      // userType: "string",
      clientType: body.clientType,
      mailTo: contactEmail.sales.email,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postTech = (body) => async (dispatch) => {
  try {
    await axios.post("/api/EmailManagement/TechSupport", {
      name: body.name,
      jobtitle: body.job,
      company: body.company,
      NewsletterSubscribe: body.isNewsletter,
      email: body.email,
      phone: body.phone,
      fax: body.fax,
      sku: body.mpn,
      address: body.address,
      address2: body.altAdderss,
      city: body.city,
      state: body.state,
      postal: body.postal,
      country: body.country,
      mpn: body.mpn,
      serial: body.serial,
      subject: body.subject,
      comments: body.comment,
      role: body.clientType,
      mailTo: contactEmail.support.email,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postFaq = (body) => async (dispatch) => {
  try {
    await axios.post("/api/Home/PostFAQ", body);
    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const postInquiry = (body) => async (dispatch, getState) => {
  const userType = getState().auth.userInfo.AccountType;

  try {
    await axios.post("/api/Home/ProductEnquiry", body);
    await axios.post("/api/EmailManagement/ProductEnquiryMail", {
      SKU: body.sku,
      email: body.email,
      mailTo: contactEmail.sales.email,
      mailFrom: body.email,
      Company: body.company,
      Phone: body.phone,
      UserType: userType,
      EnquiryText: body.inquiry,
      NewsletterSubscribe: true,
    });

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const formStatusCustom = (text) => (dispatch) => {
  dispatch({ type: types.FORM_STATUS, payload: text });
};

export const formStatusNull = () => (dispatch) => {
  dispatch({ type: types.FORM_STATUS, payload: null });
};

export const formStatusPending = () => (dispatch) => {
  dispatch({ type: types.FORM_STATUS, payload: "pending" });
};

export const postOrders = (total) => async (dispatch, getState) => {
  const user = getState().auth;
  const orders = getState().cart.cart;
  const token = getState().auth.token;
  var decoded = jwt_decode(token);
  const email = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];

  let amount, sku, qty, stock, subtitle, title;
  orders.forEach((order) => {
    amount = `${amount} ${order.amount}`;
    sku = `${sku} ${order.id}`;
    qty = `${qty} ${order.qty}`;
    stock = `${stock} ${order.stock}`;
    subtitle = `${subtitle} ${order.subtitle}`;
    title = `${title} ${order.title}`;
  });
  const body = {
    Amount: amount,
    SKU: sku,
    QTY: qty,
    Stock: stock,
    Subtitle: subtitle,
    Title: title,
    email: email,
    mailTo: email,
    mailFrom: "orders@kanexpro.com",
    NewsletterSubscribe: true,
    userContentPlacingOrder: { Comments: "", Total: total, ...user.userInfo, orderedItems: orders },
  };
  try {
    await axios.post("/api/EmailManagement/PlacingOrder", body, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    dispatch({ type: types.FORM_STATUS, payload: 200 });
    dispatch(emptyCart());
  } catch (error) {
    console.log(error);
    dispatch({ type: types.FORM_STATUS, payload: error.response.status });
  }
};
export const reqDemo = (formData) => async (dispatch) => {
  try {
    await axios.post("/api/EmailManagement/TechSupport", formData);

    dispatch({ type: types.FORM_STATUS, payload: 200 });
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};
