import React, { useState } from "react";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  numberValidation,
  phoneOnChange,
  phoneValidation,
} from "../../validation/formValidation";
import FormInput from "./FormInput";

import { connect } from "react-redux";
import { postTech, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";
import axios from "../../api";

const FormData = (props) => {
  const { postTech, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [techData, setTechData] = useState({
    name: "",
    job: "",
    email: "",
    phone: "",
    company: "",
    fax: "",
    address: "",
    altAddress: "",
    country: "",
    state: "",
    city: "",
    postal: "",
    mpn: "",
    serial: "",
    subject: "",
    clientType: "",
    isReseller: true,
    comment: "",
    isNewsletter: false,
  });

  const [techError, setTechError] = useState({
    nameError: "",
    jobError: "",
    emailError: "",
    phoneError: "",
    companyError: "",
    faxError: "",
    addressError: "",
    altAddressError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    postalError: "",
    mpnError: "",
    serialError: "",
    subjectError: "",
    clientTypeError: "",
    isResellerError: false,
    commentError: "",
    isNewsletterError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setTechData({ ...techData, email: e.target.value });
    } else if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setTechData({ ...techData, phone: e.target.value });
    } else if (e.target.name === "postal") {
      numberValidation(e.target.value) && setTechData({ ...techData, postal: e.target.value });
    } else if (e.target.name === "isNewsletter") {
      setTechData({ ...techData, isNewsletter: !techData.isNewsletter });
    } else {
      setTechData({ ...techData, [e.target.name]: e.target.value });
    }
  };

  const onBlurInput = (e) => {
    if (e.target.name === "email" && !emailValidation(techData.email)) {
      setTechError({ ...techError, emailError: "Please enter a valid email" });
    } else if (e.target.name === "phone" && !phoneValidation(techData.phone)) {
      setTechError({ ...techError, phoneError: "Phone number must have 10 digits" });
    } else if (e.target.name === "mpn" && !notEmptyValidation(techData.mpn)) {
      setTechError({ ...techError, mpnError: "MPN is required" });
    } else if (e.target.name === "subject" && !notEmptyValidation(techData.subject)) {
      setTechError({ ...techError, subjectError: "Subject is required" });
    } else if (e.target.name === "comment" && !notEmptyValidation(techData.comment)) {
      setTechError({ ...techError, commentError: "Comment is required" });
    } else {
      // Clear the error message for the current field
      switch (e.target.name) {
        case "email":
          setTechError({ ...techError, emailError: "" });
          break;
        case "phone":
          setTechError({ ...techError, phoneError: "" });
          break;
        case "mpn":
          setTechError({ ...techError, mpnError: "" });
          break;
        case "subject":
          setTechError({ ...techError, subjectError: "" });
          break;
        case "comment":
          setTechError({ ...techError, commentError: "" });
          break;
        default:
          break;
      }
    }
  };

  const onsubmitValidation = (e) => {
    if (
      emailValidation(techData.email) &&
      phoneValidation(techData.phone) &&
      notEmptyValidation(techData.mpn) &&
      notEmptyValidation(techData.subject) &&
      notEmptyValidation(techData.comment)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!onsubmitValidation()) {
      return;
    }

    formStatusPending();

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setTechData({
            name: "",
            job: "",
            email: "",
            phone: "",
            company: "",
            fax: "",
            address: "",
            altAddress: "",
            country: "",
            state: "",
            city: "",
            postal: "",
            mpn: "",
            serial: "",
            subject: "",
            clientType: "",
            isReseller: true,
            comment: "",
          });
          setTechError({
            nameError: "",
            jobError: "",
            emailError: "",
            phoneError: "",
            companyError: "",
            faxError: "",
            addressError: "",
            altAddressError: "",
            countryError: "",
            stateError: "",
            cityError: "",
            postalError: "",
            mpnError: "",
            serialError: "",
            subjectError: "",
            clientTypeError: "",
            isResellerError: false,
            commentError: "",
            isNewsletterError: "",
          });

          await postTech(techData);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <form class="form-card" onSubmit={onSubmit}>
      <div class="d-lg-flex mb-1">
        <div className="w-100 mr-lg-3">
          <div className="text-left">
            <label className="mb-0"> Are you an:</label>
          </div>
          <select
            className="form-control form-control-sm"
            name="clientType"
            value={techData.clientType}
            onChange={onChangeData}
          >
            <option value="KanexPro Reseller">KanexPro Reseller</option>
            <option value="KanexPro Consultant">KanexPro Consultant</option>
            <option value="Educationl Institute">Educational Institute</option>
            <option value="End User">End User</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="w-100 ml-lg-3">
          <div className="text-left">
            <label className="mb-0">Are you working with a KanexPro Reseller ? </label>
          </div>
          <select
            className="form-control form-control-sm"
            name="isReseller"
            value={techData.isReseller}
            onChange={onChangeData}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
      </div>
      <div class="row justify-content text-left">
        <FormInput
          title="Name"
          name="name"
          type="text"
          value={techData.name}
          onChange={onChangeData}
          placeholder="Enter your name"
        />
        <FormInput
          title="Job Title"
          name="job"
          type="text"
          value={techData.job}
          onChange={onChangeData}
          placeholder="Enter your job title"
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="Email"
          name="email"
          value={techData.email}
          onChange={onChangeData}
          onBlur={onBlurInput}
          type="text"
          placeholder="Enter your email"
          required={true}
          error={techError.emailError}
        />
        <FormInput
          title="Phone"
          name="phone"
          type="text"
          value={techData.phone}
          onChange={onChangeData}
          onBlur={onBlurInput}
          placeholder="Enter your phone number"
          required={true}
          error={techError.phoneError}
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="Company"
          name="company"
          type="text"
          value={techData.company}
          onChange={onChangeData}
          placeholder="Enter company name"
        />
        <FormInput
          title="Fax"
          name="fax"
          type="text"
          value={techData.fax}
          onChange={onChangeData}
          placeholder="Enter fax"
          required=""
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="Address"
          name="address"
          type="text"
          value={techData.address}
          onChange={onChangeData}
          placeholder="Enter your address"
        />
        <FormInput
          title="Alt address"
          name="altAddress"
          type="text"
          value={techData.altAddress}
          onChange={onChangeData}
          placeholder="Enter your alt address"
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="Country"
          name="country"
          type="text"
          value={techData.country}
          onChange={onChangeData}
          placeholder="Enter your Country's name"
        />
        <FormInput
          title="State"
          name="state"
          type="text"
          value={techData.state}
          onChange={onChangeData}
          placeholder="Enter your state"
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="City"
          name="city"
          type="text"
          value={techData.city}
          onChange={onChangeData}
          placeholder="Enter city"
        />
        <FormInput
          title="Postal"
          name="postal"
          type="text"
          value={techData.postal}
          onChange={onChangeData}
          placeholder="Enter postal info"
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="MPN"
          name="mpn"
          type="text"
          value={techData.mpn}
          onChange={onChangeData}
          onBlur={onBlurInput}
          placeholder="Enter MPN #"
          required={true}
          error={techError.mpnError}
        />
        <FormInput
          title="Serial"
          name="serial"
          type="text"
          value={techData.serial}
          onChange={onChangeData}
          placeholder="Enter serial #"
        />
      </div>

      <div class="row justify-content text-left">
        <FormInput
          title="Subject"
          name="subject"
          type="text"
          value={techData.subject}
          onChange={onChangeData}
          onBlur={onBlurInput}
          placeholder="Enter subject"
          required={true}
          error={techError.subjectError}
        />
      </div>
      <div className="textarea-wrapper">
        <textarea
          placeholder="Please include serial number if applicable"
          class="pb-cmnt-textarea"
          id="text"
          name="comment"
          required={true}
          value={techData.comment}
          onChange={onChangeData}
          onBlur={onBlurInput}
          rows="5"
        ></textarea>
        {techError.commentError && <p className="invalid-data">{techError.commentError}</p>}
      </div>
      <div class="check">
        <input
          class="check-input"
          type="checkbox"
          name="isNewsletter"
          value={techData.isNewsletter}
          onChange={onChangeData}
          id="flexCheckDefault1"
        />
        <label class="check-label" for="flexCheckDefault1">
          Yes, please send me newsletter on KanexPro
        </label>
      </div>
      <button class="btn btn-primary float-xs-right" type="submit">
        Submit
      </button>
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postTech, formStatusPending, formStatusNull, formStatusCustom })(FormData);
